import Modal from 'react-modal';
import React, { useState } from 'react';
import { setCustomConfig, getCustomConfig, getHash, resetConfig, overwriteConfig, getEnv } from '../utils/config';
import { useForm } from 'react-hook-form';
import InputWithLabel from './molecules/form/InputWithLabel';
import Button from './atoms/Button';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { asbtractLocation } from '../utils/window/location';

const LittleMenu = styled('div')(({ theme }) => ({
  height: 10,
  borderRadius: '0px 0px 5px 5px',
  position: 'absolute',
  right: 0,
  left: 0,
  top: 0,
  margin: 'auto',
  backgroundColor: theme.colors.secondaryLight,
  zIndex: 101,
  width: 200
}));

const DevMenu = ({ displayed }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit } = useForm();

  if (!displayed) {
    return null;
  }
  if (URLSearchParams && overwriteConfig(new URLSearchParams(asbtractLocation().search).entries())) {
    window.location.href = '/';
  }
  const resetAll = () => {
    resetConfig();
    window.location.reload();
  };
  const onSubmit = data => {
    for (const [key, value] of Object.entries(data)) {
      setCustomConfig(key, value);
      window.location.reload();
    }
  };
  const envs = Object.entries(getEnv());

  envs.sort((a, b) => (a[0] < b[0] ? -1 : 1));
  return (
    <>
      <LittleMenu onClick={() => setIsOpen(isOpen => !isOpen)} />
      <Modal isOpen={isOpen}>
        <Button onClick={resetAll}>Reset</Button>
        <Button styleType="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <h1>{getHash()}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul style={{ columnCount: 2 }}>
            {envs.map(([key, value]) => {
              return (
                <li key={key}>
                  <InputWithLabel
                    errorMessage={getCustomConfig(key) ? 'Overwritten' : ''}
                    ref={register}
                    label={`${key}`}
                    name={key}
                    placeholder={`${value}`}
                    defaultValue={getCustomConfig(key)}
                    style={{ width: '100%' }}
                  />
                </li>
              );
            })}
          </ul>
          <Button type="submit" mr={2}>
            Save
          </Button>
          <Button styleType="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </form>
      </Modal>
    </>
  );
};

DevMenu.propTypes = {
  displayed: PropTypes.bool
};

export default DevMenu;
