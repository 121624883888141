import React from 'react';
import Labelize from './Labelize';
import InputField from '../../atoms/inputField/InputField';
import { string } from 'prop-types';

const InputWithLabel = React.forwardRef(({ label, errorMessage, inline, tooltip, suffix, ...props }, ref) => {
  return (
    <Labelize
      label={label}
      errorMessage={errorMessage}
      inline={inline}
      tooltip={tooltip}
      suffix={suffix}
      inputName={props.name}>
      <InputField ref={ref} inline={inline} {...props} />
    </Labelize>
  );
});

InputWithLabel.propTypes = {
  ...InputField.propTypes,
  label: string,
  errorMessage: string,
  tooltip: string
};

export default InputWithLabel;
